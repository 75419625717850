import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CardBox from '../components/CardBox'
import Footer from '../components/Footer'
import GetQuote from '../components/Getquote'
import IconText from '../components/IconText'
import Layout from '../components/Layout'
import Location from '../components/Location'
import SEO from '../components/seo'
import email from '../images/email-orange.svg'
import fax from '../images/fax-orange.svg'
import location from '../images/location-icon.svg'
import phone from '../images/phone-orange.svg'

const ContactUsPage = ({data}) => {

  const { seo, contactPage: page } = data.wpPage
  const { contactBanner, content, contact } = page;
  console.log(seo)
  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
      />
      <section className="contactus">
        <BackgroundImage fluid={contactBanner?.image?.localFile?.childImageSharp?.fluid}>
          <div className="contactus__header">
            <div className="contactus__overlay" />
            <Container>
              <Row>
                <Col className="contactus__col" xl={8}>
                  <h1 className="contactus__heading">{contactBanner?.title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: contactBanner?.description }} />
                </Col>
                <Col className="contactus__col" xl={4}>
                  <GetQuote title="Get In Touch With Us" buttonLabel="Submit" />
                </Col>
              </Row>
            </Container>
          </div>
        </BackgroundImage>
        <Container className="contactus__container">
          <Row>
            <Col className="contactus__col-low" xl={5} dangerouslySetInnerHTML={{ __html: content }} />
            <Col className="contactus__col-low" xl={1} />
            <Col className="contactus__col-low" xl={3}>
              <CardBox>
                <IconText
                  text="CALL US AT"
                  icon={phone}
                  description={contact?.phone}
                  invert
                />
                <IconText
                  text="FAX US AT"
                  icon={fax}
                  description={contact?.fax}
                  invert
                />
                <IconText
                  text="EMAIL US AT"
                  icon={email}
                  description={contact?.email}
                  invert
                />
              </CardBox>
            </Col>
            <Col className="contactus__col-low" xl={3}>
              <CardBox>
                <IconText
                  text="OFFICE ADDRESS"
                  icon={location}
                  description={contact?.officeAddress}
                  invert
                />
                <IconText
                  text="OFFICE ADDRESS"
                  icon={location}
                  description={contact?.warehouseAddress}
                  invert
                />
              </CardBox>
            </Col>
          </Row>
        </Container>
        <h3 className="contactus__ter-heading">We Are Located Here</h3>
      </section>
      <Location />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query getContactDataAndDesktop($id: String) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      contactPage {
        contactBanner {
          title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        content
        contact {
          email
          fax
          officeAddress
          phone
          warehouseAddress
        }
      }
    }
  }
`


export default ContactUsPage
