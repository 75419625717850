import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Image } from 'react-bootstrap'
import locationIcon from '../images/location-icon.svg'
import phoneIcon from '../images/phone-orange.svg'
import emailIcon from '../images/email-orange.svg'
import Button from '../components/Button'
// import GoogleMapReact from 'google-map-react'

const Location = () => {
  const data = useStaticQuery(
    graphql`
      query getLocation {
        wpPage(
          uri: {
            eq: "/top-shipping-company-toronto-roadlinx-freight-container/"
          }
        ) {
          homePage {
            location {
              phone
              email
              officeAddress
              warehouseAddress
              newsletterHeading
              mapSource
            }
          }
        }
      }
    `
  )
  const {
    phone,
    email,
    officeAddress,
    warehouseAddress,
    newsletterHeading,
    mapSource,
  } = data.wpPage.homePage.location
  return (
    <div className="location">
      <Container fluid={true}>
        <Row>
          <Col className="location__col-left" lg={8}>
            <div className="location__address-wrapper">
              <div className="location__address-sub-wrapper">
                <div className="location__address-ter-wrapper">
                  <Image
                    src={phoneIcon}
                    fluid={true}
                    className="location__icon"
                  />
                  <div className="location__heading">{phone}</div>
                </div>
                <div className="location__address-ter-wrapper mt-3">
                  <Image
                    src={emailIcon}
                    fluid={true}
                    className="location__icon"
                  />
                  <div className="location__heading">{email}</div>
                </div>
              </div>
              <div className="location__address-sub-wrapper">
                <div className="location__address-ter-wrapper">
                  <Image
                    src={locationIcon}
                    fluid={true}
                    className="location__icon"
                  />
                  <div className="location__heading">Office Address</div>
                </div>
                <div className="location__address">{officeAddress}</div>
              </div>
              <div className="location__address-sub-wrapper">
                <div className="location__address-ter-wrapper">
                  <Image
                    src={locationIcon}
                    fluid={true}
                    className="location__icon"
                  />
                  <div className="location__heading">Warehouse Address</div>
                </div>
                <div className="location__address">{warehouseAddress}</div>
              </div>
            </div>
          </Col>
          <Col className="location__col-right" lg={4}>
            <p className="location__text">{newsletterHeading}</p>
            <div className="location__subscription-wrapper">
              <input
                type="email"
                placeholder="Your Email"
                className="location__input"
              ></input>
              <Button type="secondary" label="SUBSCRIBE" />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="location__maps-wrapper">
        <iframe
          src={mapSource}
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  )
}

export default Location
